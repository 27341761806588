<template>
  <div @click.self="closeModal" class="modalContainer" data-cy="create-account-modal">
    <div class="modalWrapper">
      <div @click="closeModal" class="close" data-cy="close-create-account-modal">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1161 6.6228L7.75908 6.97635L8.11437 7.33163L13.4572 12.6745L12.6745 13.4572L11.8466 12.6294L11.8449 12.6277L7.37718 8.11608L7.02364 7.75906L6.66835 8.11435L1.32551 13.4572L0.542786 12.6745L5.88563 7.33163L6.24062 6.97664L5.8842 6.62309L0.543158 1.32514L1.32551 0.542787L6.66835 5.88563L7.02334 6.24061L7.37689 5.88419L12.6748 0.543155L13.4572 1.32551L12.6294 2.15333L12.6277 2.15504L8.1161 6.6228ZM12.7648 13.5475C12.7649 13.5476 12.765 13.5478 12.7651 13.5479L12.7648 13.5475ZM0.452113 12.7651L0.452145 12.7651C0.452134 12.7651 0.452123 12.7651 0.452113 12.7651Z"
            fill="black"
            stroke="black"
          />
        </svg>
      </div>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <h2 style="text-align: center">Create Your Account</h2>
      <div class="login">
        <p>Already have an account?</p>
        <a @click="loginClicked" href="javascript:;"> LOGIN </a>
      </div>
      <div class="nameWrapper">
        <div class="inputWrapper">
          <label for="firstName">First Name</label>
          <input
            @keyup.enter="signUp"
            v-model="signUpInfo.firstName"
            @input="formatNameWhileTyping(signUpInfo.firstName)"
            type="text"
            placeholder="First Name"
            id="firstName"
            data-cy="first-name-input"
          />
          <p v-if="v$.signUpInfo.firstName.$error === true" class="validation" data-cy="first-name-validation-error">
            Please enter your first name
          </p>
        </div>
        <div class="inputWrapper">
          <label for="lastName">Last Name</label>
          <input
            @keyup.enter="signUp"
            v-model="signUpInfo.lastName"
            @input="formatLastNameWhileTyping(signUpInfo.lastName)"
            type="text"
            placeholder="Last Name"
            id="lastName"
            data-cy="last-name-input"
          />
          <p v-if="v$.signUpInfo.lastName.$error === true" class="validation" data-cy="last-name-validation-error">
            Please enter your last name
          </p>
        </div>
      </div>
      <div class="inputWrapper">
        <label for="email">Email</label>
        <input
          @keyup.enter="signUp"
          v-model="signUpInfo.email"
          @input="formatEmailWhileTyping(signUpInfo.email)"
          type="email"
          placeholder="Email"
          id="email"
          inputmode="email"
          data-cy="create-account-email-input"
        />
        <p
          v-if="v$.signUpInfo.email.$error || !isValidEmail(signUpInfo.email)"
          class="validation"
          data-cy="email-validation-error"
        >
          Please enter your email address
        </p>
      </div>
      <div class="inputWrapper">
        <label for="companyName">Company Name (Optional)</label>
        <input
          @keyup.enter="signUp"
          v-model="signUpInfo.companyName"
          type="text"
          placeholder="Company Name"
          id="companyName"
        />
      </div>
      <div class="inputWrapper">
        <label for="birthday">Birthday</label>
        <input
          @keyup.enter="signUp"
          @input="formatBirthdateWhileTyping"
          v-model="signUpInfo.birthdate"
          type="text"
          placeholder="mm/dd/yyyy"
          id="birthday"
          inputmode="numeric"
          data-cy="birthday-input"
        />
        <p class="info">So we can offer you special disounts on your birthday</p>
        <p v-if="birthdateError" class="validation">{{ birthdateError }}</p>
      </div>
      <div class="inputWrapper">
        <label for="phone">Cell Phone Number</label>
        <input
          @keyup.enter="signUp"
          @input="formatPhoneNumberWhileTyping(signUpInfo.phone)"
          v-model="signUpInfo.phone"
          type="text"
          placeholder="Cell Phone Number"
          id="phone"
          inputmode="tel"
          data-cy="phone-input"
        />
        <p v-if="v$.signUpInfo.phone.$error === true" class="validation" data-cy="phone-validation-error">
          Please enter your cell phone number
        </p>
        <p v-if="validPhoneNumberError" class="validation" data-cy="phone-validation-error">
          Please enter a valid phone number. Phone number can't start with 1
        </p>
        <p class="info">To verify your account, we'll send a 6 digit code via SMS to this number.</p>
      </div>
      <div class="inputWrapper">
        <label for="password">Password</label>
        <input
          @keyup.enter="signUp"
          v-model="signUpInfo.password"
          :type="passwordInputType"
          placeholder="Password"
          id="password"
          data-cy="create-account-password-input"
        />
        <p
          v-if="
            v$.signUpInfo.password.$errors.length != 0 && v$.signUpInfo.password.$errors[0].$validator == 'required'
          "
          class="validation"
          data-cy="password-validation-error"
        >
          Please enter your password
        </p>
        <p v-if="passwordIsValid === false" class="validation" data-cy="password-length-validation-error">
          Please enter a valid password
        </p>
        <a @click="togglePassword" class="passwordToggle" href="#">
          <img :src="passwordIconPath" alt="password" />
        </a>
        <PasswordValidator :password="signUpInfo.password" @valid="checkIfPasswordValid" />
      </div>
      <div v-if="storeConfig?.email_optin || storeConfig?.sms_optin" class="optIn">
        <div v-if="storeConfig?.email_optin" class="checkboxWrapper">
          <input type="checkbox" id="emainOptin" v-model="signUpInfo.emailOptin" />
          <label class="checkboxLabel" for="emainOptin">I want to receive email updates and promotions.</label>
        </div>
        <div v-if="storeConfig?.sms_optin" class="checkboxWrapper">
          <input type="checkbox" id="smsOptin" v-model="signUpInfo.phoneOptin" />
          <label class="checkboxLabel" for="smsOptin"
            >I want to receive order updates and promotions via text. Message and data rates may apply.</label
          >
        </div>
      </div>
      <div class="consent">
        <p>
          By creating an account, you agree to <br />
          Lunchbox's
          <span><a href="https://lunchbox.io/terms" target="blank_">Terms of Service</a></span>
          &
          <span><a href="https://lunchbox.io/privacy" target="blank_">Privacy Policy</a></span>
        </p>
      </div>
      <p class="feedback error" v-if="error.status">{{ error.message }}</p>
      <button @click="signUp" :disabled="buttonLoader" data-cy="create-account-button">
        <div v-if="buttonLoader" class="loader buttonLoader"></div>
        <template v-if="!buttonLoader">SIGN UP</template>
      </button>
      <div v-if="branding?.sso?.google?.enabled || branding?.sso?.apple?.enabled" class="seperator">
        <div class="line"></div>
        <p>OR</p>
        <div class="line"></div>
      </div>
      <div
        v-if="branding?.sso?.google?.enabled || (branding?.sso?.apple?.enabled && checkAppleSSOAvailable)"
        class="ssoButtons"
      >
        <GoogleSSO
          v-if="branding?.sso?.google?.enabled"
          @getCustomerInfo="getCustomerInfo"
          @ssoError="ssoError"
          @showLoader="showLoader"
          id="googleButton"
        />
        <AppleSSO
          v-if="branding?.sso?.apple?.enabled && checkAppleSSOAvailable"
          @getCustomerInfo="getCustomerInfo"
          @ssoError="ssoError"
          @showLoader="showLoader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

import { mapState, mapMutations } from 'vuex'

import modalMixins from '@/mixins/modalMixins.js'
import formattingMixins from '@/mixins/formattingMixins'
import fullStoryMixins from '@/mixins/fullStoryMixins'

import customersApi from '@/api/customersApi.js'

import PasswordValidator from '@/components/Elements/PasswordValidator.vue'
import GoogleSSO from '@/components/Elements/GoogleSSO.vue'
import AppleSSO from '@/components/Elements/AppleSSO.vue'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      signUpInfo: {
        firstName: this.firstName || '',
        lastName: this.lastName || '',
        email: this.email || '',
        phone: this.formatPhoneNumber(this.phone) || '',
        password: '',
        birthdate: '',
        companyName: '',
        emailOptin: true,
        phoneOptin: true,
      },
      passwordInputType: 'password',
      passwordIconPath: require('@/assets/icons/passwordShow.svg'),
      error: {
        status: false,
        message: '',
      },
      passwordIsValid: null,
      validPhoneNumberError: false,
      birthdateError: null,
    }
  },
  validations() {
    return {
      signUpInfo: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: { required },
        password: {
          required,
          minLength: minLength(6),
        },
        birthdate: this.signUpInfo.birthdate ? { required, birthdateValid: this.validateBirthdate } : {},
      },
    }
  },
  components: {
    PasswordValidator,
    GoogleSSO,
    AppleSSO,
  },
  props: ['email', 'phone', 'firstName', 'lastName'],
  mixins: [modalMixins, formattingMixins, fullStoryMixins],
  computed: {
    ...mapState(['buttonLoader', 'branding', 'cookies', 'storeConfig', 'copperDetails']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
    checkAppleSSOAvailable() {
      if (!window.AppleID) return false
      return true
    },
  },
  emits: ['closeModal', 'toggleCopperInfo', 'loginClicked', 'accountCreated', 'openVerifyEmailModal'],
  methods: {
    ...mapMutations([
      'SET_LOGGED_IN',
      'SET_BUTTON_LOADER',
      'SET_CUSTOMER_DATA',
      'SET_PHONE_VERIFIED',
      'SET_COOKIES',
      'SET_TOASTER',
    ]),
    closeModal() {
      this.$emit('closeModal')
    },
    toggleCopperInfo() {
      this.$emit('toggleCopperInfo')
    },
    loginClicked() {
      this.$emit('closeModal')
      this.$emit('loginClicked')
    },
    checkIfPasswordValid(valid) {
      this.passwordIsValid = valid
    },
    ssoError() {
      this.error.status = true
      this.error.message = 'Something went wrong. Please try again'
      this.SET_BUTTON_LOADER(false)
    },
    showLoader() {
      this.SET_BUTTON_LOADER(true)
    },
    signUp() {
      this.v$.$touch()
      if (!this.isValidEmail(this.signUpInfo.email)) {
        this.error.status = true
        this.error.message = 'Please enter a valid email address'
        return
      }
      this.checkIfPasswordValid(this.passwordIsValid)
      if (this.v$.$error || this.passwordIsValid === null || this.passwordIsValid === false) {
        return
      }
      if (this.signUpInfo.phone.startsWith('(1')) {
        this.validPhoneNumberError = true
        return
      }
      this.error.status = false
      this.error.message = ''
      this.SET_BUTTON_LOADER(true)

      let formattedBirthdate = ''
      if (this.signUpInfo.birthdate != '') {
        // check if birthdate matches MM/DD/YYYY
        if (this.signUpInfo.birthdate.length > 0 && !moment(this.signUpInfo.birthdate, 'MM/DD/YYYY', true).isValid()) {
          this.error.status = true
          this.error.message = 'Birthday must be in MM/DD/YYYY format'
          this.SET_BUTTON_LOADER(false)
          return
        }

        // get todays date
        let todaysDate = moment().format('YYYY-MM-DD')
        // check if birthdate is after todaysDate
        if (
          this.signUpInfo.birthdate.length > 0 &&
          moment(this.signUpInfo.birthdate, 'MM/DD/YYYY').isAfter(todaysDate, 'YYYY-MM-DD')
        ) {
          this.error.status = true
          this.error.message = 'Please enter a valid birthdate'
          this.SET_BUTTON_LOADER(false)
          return
        }

        // get the date from 13 years ago
        let maxDate = moment().subtract(13, 'years').format('YYYY-MM-DD')
        // check if birthdate is after maxDate
        if (
          this.signUpInfo.birthdate.length > 0 &&
          moment(this.signUpInfo.birthdate, 'MM/DD/YYYY').isAfter(maxDate, 'YYYY-MM-DD')
        ) {
          this.error.status = true
          this.error.message = 'You must be at least 13 years old to sign up'
          this.SET_BUTTON_LOADER(false)
          return
        }

        // check if phone number is valid
        if (this.signUpInfo.phone.length > 0 && this.signUpInfo.phone.length < 14) {
          this.error.status = true
          this.error.message = 'Please enter a valid phone number'
          this.SET_BUTTON_LOADER(false)
          return
        }

        if (this.signUpInfo.birthdate.length > 0) {
          // change birthdate format from mm/dd/yyyy to yyyy-mm-dd
          let splittedBirthdate = this.signUpInfo.birthdate.split('/')
          formattedBirthdate = `${splittedBirthdate[2]}-${splittedBirthdate[0]}-${splittedBirthdate[1]}`
        }
      }

      let payload = {
        email: this.signUpInfo.email,
        password: this.signUpInfo.password,
        first_name: this.signUpInfo.firstName,
        last_name: this.signUpInfo.lastName,
        phone: this.signUpInfo.phone.replace(/\D/g, ''),
        auto_login: true,
        email_optin: this.storeConfig.email_optin ? this.signUpInfo.emailOptin : false,
        sms_optin: this.storeConfig.sms_optin ? this.signUpInfo.phoneOptin : false,
        birthdate: formattedBirthdate,
        company_name: this.signUpInfo.companyName,
      }

      if (!this.signUpInfo.companyName) {
        delete payload.company_name
      }

      if (this.signUpInfo.birthdate == '') {
        delete payload.birthdate
      }

      customersApi
        .createAccount(payload)
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          // for FullStory
          this.identifyUser(response.data.data)
          this.SET_PHONE_VERIFIED(response.data.data.is_phone_verified)
          localStorage.setItem('phoneVerified', response.data.data.is_phone_verified)
          this.SET_COOKIES(response.data.cookies)
          // this.login()
          // Facebook Pixel CompleteRegistration event
          if (typeof fbq === 'function') {
            fbq('track', 'CompleteRegistration')
          }
          if (response.data.data.customer_id) {
            this.SET_LOGGED_IN(true)
            localStorage.setItem('customerId', response.data.data.customer_id)
            localStorage.setItem('auth', 'Basic ' + response.data.data.auth_cookie)
            if (this.storeConfig.require_phone_verification) {
              this.$emit('accountCreated')
            }
            if (this.storeConfig.require_email_verification) {
              this.$emit('openVerifyEmailModal')
            }
            if (this.copperDetails) {
              this.toggleCopperInfo()
            }
            this.closeModal()
            this.SET_BUTTON_LOADER(false)
            this.SET_COOKIES(response.data.cookies)
          } else {
            this.login()
          }
        })
        .catch((error) => {
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          if (error.response.data.data && error.response.data.data.error) {
            this.error.message = error.response.data.data.error
          } else {
            this.error.message = 'Something went wrong. Please try again.'
          }
        })
    },
    login() {
      let payload = {
        username: this.signUpInfo.email,
        password: this.signUpInfo.password,
      }

      customersApi
        .login(payload)
        .then((response) => {
          this.SET_LOGGED_IN(true)
          localStorage.setItem('customerId', response.data.data.customer_id)
          localStorage.setItem('auth', 'Basic ' + response.data.data.auth_cookie)
          if (this.storeConfig.require_phone_verification) {
            this.$emit('accountCreated')
          }
          if (this.storeConfig.require_email_verification) {
            this.$emit('openVerifyEmailModal')
          }
          if (this.copperDetails) {
            this.toggleCopperInfo()
          }
          this.closeModal()
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again.'
        })
    },
    getCustomerInfo() {
      this.SET_BUTTON_LOADER(true)
      customersApi
        .getCustomerInfo()
        .then((response) => {
          let toasterData = {
            show: true,
            message: 'Welcome back',
          }
          this.SET_TOASTER(toasterData)
          this.SET_CUSTOMER_DATA(response.data.data)
          // for FullStory
          this.identifyUser(response.data.data)
          this.SET_PHONE_VERIFIED(response.data.data.is_phone_verified)
          localStorage.setItem('phoneVerified', response.data.data.is_phone_verified)
          this.SET_LOGGED_IN(true)
          if (this.copperDetails) {
            this.toggleCopperInfo()
          }
          this.closeModal()
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
    formatBirthdateWhileTyping(event) {
      let birthdate = event.target.value

      // Remove any non-numeric and non-slash characters
      birthdate = birthdate.replace(/[^0-9/]/g, '')

      // Handle deletion gracefully
      if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
        this.signUpInfo.birthdate = birthdate
        return
      }

      // Automatically format the birthdate with slashes
      if (birthdate.length > 2 && birthdate[2] !== '/') {
        birthdate = birthdate.slice(0, 2) + '/' + birthdate.slice(2)
      }
      if (birthdate.length > 5 && birthdate[5] !== '/') {
        birthdate = birthdate.slice(0, 5) + '/' + birthdate.slice(5)
      }

      // Limit to 10 characters (mm/dd/yyyy)
      if (birthdate.length > 10) {
        birthdate = birthdate.slice(0, 10)
      }

      // Update the input value
      this.signUpInfo.birthdate = birthdate
    },
    togglePassword() {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text'
        this.passwordIconPath = require('@/assets/icons/passwordHide.svg')
      } else {
        this.passwordInputType = 'password'
        this.passwordIconPath = require('@/assets/icons/passwordShow.svg')
      }
    },
    validateBirthdate(value) {
      const birthdate = new Date(value)
      const year = birthdate.getFullYear()
      if (year < 1900) {
        this.birthdateError = 'Please enter a valid date of birth. Birthdays before 1900 are not accepted.'
        return false
      }
      this.birthdateError = null
      return true
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('firstName').focus()
    })
  },
}
</script>

<style src="../../../assets/css/Modals/AuthenticationModals/SignUpModal.css" scoped></style>
