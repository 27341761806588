import axios from 'axios'

const getWeather = async (lat, lng) => {
  let url = 'https://api.open-meteo.com/v1/forecast'
  return await axios.get(url, {
    params: {
      latitude: lat,
      longitude: lng,
      current_weather: true,
      timezone: 'auto',
      temperature_unit: 'fahrenheit',
    },
    withCredentials: false,
  })
}

const getLocationByIp = async () => {
  let url = 'https://freeipapi.com/api/json'
  return await axios.get(url, {
    withCredentials: false,
  })
}

const getAddresses = async (address, layer, countryCode) => {
  if (!address) return;
  let encodedAddress = encodeURIComponent(address);
  let url = `https://api.radar.io/v1/search/autocomplete?query=${encodedAddress}&limit=5&countryCode=${countryCode}&layers=${layer}`;
  return await axios.get(url, {
    headers: {
      Authorization: 'prj_live_pk_3b40e3b1526b84d6a1d203736df2da66a0a8d082'
    },
    withCredentials: false,
  })
}

export default {
  getWeather,
  getLocationByIp,
  getAddresses,
}
