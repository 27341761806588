<template>
  <input
    type="text"
    :placeholder="placeholder"
    @keydown="getAddressAutoSuggestions"
    v-model="addressAutoComplete"
    class="radarAutoCompleteInput"
    :class="{ addressInput: from == 'home' }"
    ref="autocompleteInput"
  />
  <div
    v-if="addressAutoCompleteListIsOpen"
    ref="addressSuggestions"
    class="addressSuggestions"
    :class="{ darkMode: branding.dark_mode_enabled }"
    :style="{ top: label ? '70px' : '50px' }"
  >
    <div v-if="addressAutoCompleteList?.length > 0">
      <div
        v-for="(address, index) in addressAutoCompleteList"
        :key="index"
        @click="addressSelected(address)"
        class="suggestion"
      >
        <img src="@/assets/icons/marker.svg" alt="" />
        <p>{{ address.formattedAddress }}</p>
      </div>
    </div>
    <div v-else>
      <p class="suggestion">No result for '{{ addressAutoComplete }}'</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import externalApi from '@/api/externalApi'

export default {
  data() {
    return {
      addressAutoComplete: '',
      debounce: null,
      addressAutoCompleteList: null,
      addressAutoCompleteListIsOpen: false,
    }
  },
  props: ['placeholder', 'from', 'reqiresAddress', 'label'],
  computed: {
    ...mapState(['selectedService', 'branding', 'storeConfig']),
  },
  emits: ['addressSelected'],
  methods: {
    getAddressAutoSuggestions() {
      const countryCode = this.storeConfig?.country_code || 'US'
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let layer = this.reqiresAddress ? 'address' : 'address,coarse'
        externalApi
          .getAddresses(this.addressAutoComplete, layer, countryCode)
          .then((response) => {
            this.addressAutoCompleteList = response.data.addresses
            this.addressAutoCompleteListIsOpen = true
          })
          .catch((error) => {
            console.log(error)
          })
      }, 300)
    },
    addressSelected(addressData) {
      this.$emit('addressSelected', addressData)
      this.addressAutoCompleteListIsOpen = false
      this.addressAutoComplete = addressData.formattedAddress
    },
    resetAddressInput() {
      this.addressAutoComplete = ''
      this.addressAutoCompleteList = null
      this.$refs.autocompleteInput.value = ''
    },
  },
  mounted() {
    // if upsellDropdown is open, close it when user clicks outside of it
    document.addEventListener('click', (e) => {
      if (this.addressAutoCompleteListIsOpen) {
        if (!this.$refs.addressSuggestions?.contains(e.target)) {
          this.addressAutoCompleteListIsOpen = false
        }
      }
    })
  },
}
</script>

<style scoped>
.addressSuggestions {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  padding: 5px 0px;
  z-index: 2;
}
.suggestion {
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}
.suggestion:last-child {
  border-bottom: none;
}
.suggestion img {
  height: 12px;
}
.suggestion p {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.suggestion:hover {
  background-color: var(--gray-color);
}
.addressInput {
  border: none;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  padding: 10px 0px;
}
.remove {
  position: absolute;
  top: 3px;
  right: 0px;
  padding: 10px;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  border-radius: 5px;
}
.remove a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove a img {
  height: 14px;
  width: 14px;
}
.darkMode {
  background-color: var(--primary-color);
}
</style>
